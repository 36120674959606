<template>
 <div class="v_login">
   <h3 class="v-login-title g-flex-align-center g-flex-justify-center">欢迎登录</h3>
   <div class="v-login-form g-flex-column">
     <div class="v-login-form-item g-flex-column">
       <div class="v-login-form-item-title g-flex-align-center">
        <span>手机号</span>
       </div>
       <div class="v-login-form-item-input">
        <van-field type="tel" v-model="form.mobile"  placeholder="请输入手机号" clearable/>
       </div>
     </div>
     <div class="v-login-form-item g-flex-column">
       <div class="v-login-form-item-title g-flex-align-center">
        <span>密码</span>
       </div>
       <div class="v-login-form-item-input v-login-form-item-pwd">
        <van-field :type="pwdType" v-model="form.password"  placeholder="请输入密码"/>
        <i class="iconfont" :class="pwdType ==='password' ? 'iconbukejian1': 'iconmimakejianguanbi'" @click="pwdTypeClick"></i>
       </div>
     </div>

    <div class="v-login-btn g-flex-justify-between">
      <operation-button :isPadding="false" :buttonObj="buttonObj" @clickItem="emitLoginClick"/>
    </div>

    <div class="v-login-tips-list g-flex-justify-between">
      <div class="v-login-tips-list-item" @click="$router.push({ name: 'forgetpwd' })">
        <span>忘记密码</span>
      </div>
      <!-- <div class="v-login-tips-list-item" @click="$router.push({ name: 'register' })">
        <span>去注册</span>
      </div> -->
    </div>
   </div>



 </div>
</template>

<script>
import OperationButton from '../../components/OperationButton.vue'
import { apiLogin } from '@/utils/api.js'
export default {
  components: { OperationButton },
  data() {
    return {
      pwdType: 'password',
      form: {
        mobile: '',
        password: ''
      },
      buttonObj: {
        title: '登录',
        claSS: 'c-btn-default c-btn-border-radius',
        event: 'apiLoginHandel'
      }
    }
  },
  methods: {
    // 登录按钮触发
    emitLoginClick(event) {
      if(!event) return
      this[event]()
    },
    // 登录
    async apiLoginHandel() {
      if(!(/^1\d{10}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
        if(!this.form.password) return this.$toast('密码不能为空')
        const { success, data } = await apiLogin(this.form)
        console.log(data)
        if(!success) return
        this.$global.setToken(data.token)
        this.$toast(data.msg)
        this.$router.replace({ path:'/' })
    },
    pwdTypeClick() {
      if(this.pwdType === 'password') return this.pwdType = 'text'
      return this.pwdType = 'password'
    }
  }
}
</script>

<style lang='scss'>
.v_login {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: url('/image/daili_login_bg.png') no-repeat;
  background-size: cover;
  background-position: center;
  .v-login-title {
    padding-top: 120px;
    color: $main_color;
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: bold;
  }
  .v-login-form {
    padding: 45px 46px 20px 46px;
    .v-login-form-item {
      background: transparent;
      &:nth-of-type(2) {
        margin-top: 36px;
      }
      .v-login-form-item-title {
        padding-bottom: 16px;
        span {
          color: #246EC8;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .v-login-form-item-input {
        border-bottom: 1px solid #DCDCDC;
        // 密码
        &.v-login-form-item-pwd {
          position: relative;
          .iconfont {
            position: absolute;
            right: 0;
            font-size: 24px;
            padding: 5px;
            top: -2px;
            color:  #333333;
          }
        }
        .van-cell {
          padding: 0;
          padding-bottom: 12px;
          border: none;
          background: transparent;
          &::after {
            border-bottom: none;
          }
          input {
            font-size: 16px;
            &::-webkit-input-placeholder {
              color: #CACACA;
              font-size: 16px;
            }
          }
          .van-field__body {
            .van-field__control {
              color: #333333;
            }
            .van-icon-clear {
              color:  #333333;
              font-size: 18px;
            }
          }
        }
      }
    }

    .v-login-btn {
      margin-top: 28px;
    }

    .v-login-tips-list {
      padding-top: 20px;
      .v-login-tips-list-item {
        color: $main_color;
        font-size: 14px;
        padding: 8px 5px;
      }
    }
  }


}
</style>